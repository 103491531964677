<template>
  <div class="order-success">
    <div class="media">
      <img class="image" width="240" height="180" :src="$trans('modal.order.success.image') + '?rand=' + Math.random()">
    </div>

    <h4 class="title is-spaced" v-html="$trans('modal.order.success.title')"></h4>
    <p class="subtitle">{{ $trans('modal.order.success.subtitle') }}</p>
  </div>
</template>

<style lang="scss">
.order-success {
  text-align: center;

  .image {
    margin: 0 auto;
  }
}
</style>