<template>
  <div :id="id" ref="section" class="scroll-margin-top">
    <section :class="['section is-section-expanded', 'is-' + id]">
      <div class="container">
        <picture v-if="cover" class="lozad">
          <source media="(min-width: 940px)" :srcset="cover.desktop">
          <source media="(min-width: 640px)" :srcset="cover.tablet">
          <source media="(min-width: 0px)" :srcset="cover.mobile">
        </picture>

        <div class="content">
          <span class="number">{{ number }}</span>

          <span class="label is-uppercase">{{ label }}</span>
          <h2 class="title is-uppercase">{{ title }}</h2>
          <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>

          <button @click.prevent="toggle" type="button" :class="buttonClass">
            {{ this.opened ? $trans('buttons.close') : $trans('buttons.more') }}
          </button>
        </div>
      </div>
    </section>

    <transition name="slide">
      <component ref="view" v-if="opened" :is="`${id}-view`"></component>
    </transition>
  </div>
</template>

<script>

import TuningView from "@/views/TuningView";
import DevelopmentView from "@/views/DevelopmentView";
import ConstructionView from "@/views/ConstructionView";
import InvestmentView from "@/views/InvestmentView";

export default {
  components: {TuningView, DevelopmentView, ConstructionView, InvestmentView},
  props: {
    id: {
      type: String,
      required: true
    },

    cover: {
      type: Object
    },

    label: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: ''
    },

    number: {
      type: Number
    }
  },

  data() {
    return {
      opened: false
    }
  },

  mounted() {
    this.emitter.on('close-expanded', section => {
      if (this.$refs.section.getAttribute('id') === section) {
        this.toggle();

        setTimeout(() => {
          const offsetTop = this.$refs.section.offsetTop;
          const headerHeight = document.querySelector('.app-header').offsetHeight;
          const to = offsetTop - headerHeight;

          window.scroll({ top: to, left: 0, behavior: 'smooth' });
        }, 500)
      }
    });
  },

  computed: {
    buttonClass() {
      return ['button is-outlined', {'is-primary has-arrow-right': !this.opened}, {'is-danger has-close': this.opened}]
    }
  },

  methods: {
    toggle() {
      this.opened = !this.opened;

      if(this.opened) {
        this.$nextTick(() => {
          const offsetTop = this.$el.querySelector('.view').offsetTop;
          const headerHeight = document.querySelector('.app-header').offsetHeight;
          const to = offsetTop - headerHeight;

          window.scroll({ top: to, left: 0, behavior: 'smooth' });
        })
      }
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.scroll-margin-top {
  scroll-margin-top: 62px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 120px;
    background: #1f1f1f linear-gradient(0deg,#000,transparent);
  }
}

.is-section-expanded {
  background-color: $dark;

  &.is-tuning,
  &.is-development {
    .number {
      left: 90px;
    }
  }

  .content {
    position: relative;
    margin: -40px 0 0;
  }

  .lozad {
    display: block;
    min-height: 400px;

    img {
      margin: -30px -30px 0;
      width: calc(100% + 60px);
      max-width: none;
    }
  }

  .number {
    position: absolute;
    top: -10px;
    left: 140px;
    color: #404041;
    font-size: 165px;
    line-height: 0.75;
    font-weight: $weight-bold;
  }

  .label {
    display: block;
    margin: 0 0 20px;
    color: $info;
  }

  .title {
    position: relative;
    margin: 0 0 20px;
    color: $white;
    max-width: 250px;
  }

  .subtitle {
    margin: 0 0 20px;
    color: #ABB1B8;
  }
}

@include tablet {
  .scroll-margin-top {
    scroll-margin-top: 88px;

    &:after {
      display: none;
    }
  }

  .is-section-expanded {
    position: relative;
    padding: 60px 30px !important;

    &.is-tuning,
    &.is-development {
      .content {
        float: right;
      }

      @include clearfix;
    }

    .lozad {
      min-height: auto;

      img {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    .number {
      top: -30px;
      left: 180px;
      font-size: 286px;
      line-height: 0.75;
    }

    .content {
      margin: 0;
      max-width: 450px;
    }

    .title {
      margin: 0 0 30px;
      max-width: none;
    }
  }
}

@include desktop {
  .is-section-expanded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 88px);

    .container {
      width: 100%;
    }

    .button {
      &.is-primary {
        transition: color .3s, background-color .3s;

        &.has-arrow-right:after {
          transition: background-color .3s;
        }

        &:hover {
          color: $white;
          background-color: $info;

          &.has-arrow-right:after {
            background-color: $white;
          }
        }
      }
    }
  }
}

@include widescreen {
  .is-section-expanded {
    .content {
      margin: 0;
      max-width: 540px;
    }
  }
}

</style>