<template>
  <div ref="swiper" class="is-swiper-vision-container">
    <swiper class="is-swiper-vision"
            @swiper="onSwiper"
            :slides-per-view="1.2"
            :spaceBetween="15"
            :initialSlide="1"
            :loop="true"
            :breakpoints="responsive"
            :autoplay="autoplay"
            :modules="modules"
            :loopedSlides="3"
            :centeredSlides="true"
    >
      <swiper-slide :key="index" v-for="(image, index) in images">
        <picture class="lozad">
          <source media="(min-width: 0px)" :srcset="image">
        </picture>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import { Autoplay } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      modules: [Autoplay],
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      responsive: {
        '640': {
          centeredSlides: false,
          slidesPerView: 2,
          loadOnTransitionStart: true
        }
      },
    }
  },

  methods: {
    onSwiper() {
      this.$observer.observe();
    }
  },

  computed: {
    images() {
      return this.$trans('vision.images');
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-swiper-vision-container {
  position: relative;
  overflow: hidden;
}

.is-swiper-vision {
  margin: 15px 0;
  cursor: ew-resize;

  .swiper-slide {
    padding-top: 50%;

    img,
    picture {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    img {
      object-fit: cover;
    }
  }
}

@include tablet {
  .is-swiper-vision {
    margin: 15px 9%;
    overflow: visible;

    .swiper-slide {
      padding-top: 40%;
    }
  }
}

@include tablet {
  .is-swiper-vision {

    .swiper-slide {
      padding-top: 35%;
    }
  }
}

</style>