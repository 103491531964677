<template>
  <app-header></app-header>

  <div class="app-sections">
    <app-hero></app-hero>

    <app-philosophy></app-philosophy>

    <app-expanded v-for="(section, key, index) in $trans('sections')" :key="key" :id="key"
                  :number="index + 1"
                  :label="section.label"
                  :title="section.title"
                  :subtitle="section.subtitle"
                  :cover="section.cover"
    >
    </app-expanded>

    <app-videos></app-videos>

    <app-about></app-about>

    <app-vision></app-vision>

    <app-blog class="is-home" :title="$trans('blog.title')" :subtitle="$trans('blog.subtitle')">
      <a :href="$trans('blog.link')" class="button has-arrow-right">{{ $trans('buttons.read') }}</a>
    </app-blog>

    <app-awards></app-awards>

    <app-base-form class="is-home" :title="$trans('form.title')"></app-base-form>

    <app-press></app-press>

    <app-open-sales></app-open-sales>
  </div>

  <app-footer></app-footer>

  <app-modal ref="gallery">
    <img :src="gallery.src" alt="">
  </app-modal>

  <app-modal ref="modal">
    <div class="box">
      <h4 class="title is-spaced has-offset-left" v-html="modal.title"></h4>

      <app-request-form :subject="modal.title"></app-request-form>
    </div>
  </app-modal>

  <app-modal ref="modalSuccess">
    <div class="box">
      <app-sent-success></app-sent-success>
    </div>
  </app-modal>
</template>

<script>

// Import Swiper styles
import 'swiper/scss';

import AppHeader from "@/components/AppHeader";
import AppHero from "@/components/AppHero";
import AppPhilosophy from "@/components/AppPhilosophy";
import AppExpanded from "@/components/AppExpanded";
import AppVideos from "@/components/AppVideos";
import AppAbout from "@/components/AppAbout";
import AppBlog from "@/components/AppBlog";
import AppAwards from "@/components/AppAwards";
import AppVision from "@/components/AppVision";
import AppBaseForm from "@/components/AppBaseForm";
import AppPress from "@/components/AppPress";
import AppOpenSales from "@/components/AppOpenSales";
import AppFooter from "@/components/AppFooter";
import AppModal from "@/components/AppModal";
import AppRequestForm from "@/components/AppRequestForm";
import AppSentSuccess from "@/components/AppSentSuccess";

export default {
  components: {
    AppSentSuccess,
    AppRequestForm,
    AppModal,
    AppFooter,
    AppOpenSales,
    AppPress,
    AppBaseForm,
    AppVision,
    AppAwards,
    AppBlog,
    AppAbout,
    AppVideos,
    AppExpanded,
    AppPhilosophy,
    AppHero,
    AppHeader
  },

  data() {
    return {
      modal: {
        title: ''
      },
      gallery: {
        src: '#'
      }
    }
  },

  mounted() {
    this.$observer.observe();

    this.emitter.on('open-gallery', src => {
      this.gallery.src = src;
      this.$refs.gallery.open();
    });

    this.emitter.on('open-form', title => {
      this.modal.title = title;
      this.$refs.modal.open()
    });

    this.emitter.on('open-modal-success', () => {
      this.$refs.modal.close();
      this.$refs.modalSuccess.open()
    });
  }
}
</script>

<style lang="scss">

:root {
  --app-height: 100vh;
}

@import "assets/sass/utilites/all";
@import "assets/sass/base/all";
@import "assets/sass/layout/all";
@import "assets/sass/components/all";
@import "assets/sass/elements/all";

</style>
