<template>
  <section class="section is-section-base-form">
    <div class="container">
      <slot name="cover"></slot>

      <div class="form">
        <h2 class="title is-spaced is-uppercase">{{ title }}</h2>
        <p v-if="subtitle" class="subtitle is-spaced">{{ subtitle }}</p>

        <app-request-form :subject="title"></app-request-form>
      </div>
    </div>
  </section>
</template>

<script>
import AppRequestForm from "@/components/AppRequestForm";

export default {
  components: {AppRequestForm},
  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-base-form {

  .title,
  .subtitle {
    max-width: 250px;
  }

  .is-cover {
    margin: -30px -30px 30px;
    width: calc(100% + 60px);
    max-width: none;
  }
}

@include tablet {
  .is-section-base-form {

    .title,
    .subtitle {
      max-width: 350px;
    }

    .is-cover {
      margin: 0 0 60px;
      width: auto;
      max-width: 100%;
    }
  }
}

@include desktop {
  .is-section-base-form {

    &.is-home {

      .container {
        flex-direction: initial;
      }

      .field {
        display: flex;
        align-items: flex-end;
      }

      .control {
        margin: 0 20px 0 0;
      }

      .input {
        margin: 0;
      }

      .form {
        width: 100%;
      }

      .is-expanded {
        width: 320px;
        flex: none;
      }
    }

    .container {
      display: flex;
      align-items: stretch;
      flex-direction: row-reverse;
    }

    .title {
      max-width: 420px;
    }

    picture {
      position: relative;
      display: block;
    }

    .is-cover {
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .form,
    picture {
      width: 50%;
      flex: none;
    }
  }
}

@include widescreen {
  .is-section-base-form {

    .title {
      max-width: 500px;
    }

    &.is-home {

      .form {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

</style>