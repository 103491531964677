<template>
  <section class="section is-dark is-section-open-sales">
    <div class="container">
      <div class="box">
       <div class="content">
         <h2 class="title is-uppercase" v-html="$trans('sales.title')"></h2>

         <ul class="tags">
           <li :key="index" v-for="(tag, index) in tags" class="tag">{{ tag }}</li>
         </ul>

         <div class="footer">
           <a :href="$trans('sales.href')" target="_blank" class="button is-sales has-arrow-right">
             {{ $trans('buttons.more')}}
           </a>

           <a :href="$trans('sales.href')">
             <img class="logo lozad" width="176" height="39" data-src="/images/logos/maphome-colored.svg">
           </a>
         </div>
       </div>

        <picture class="lozad">
          <source media="(min-width: 960px)" :srcset="$trans('sales.cover.tablet')">
          <source media="(min-width: 0px)" :srcset="$trans('sales.cover.mobile')">
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    tags() {
      return this.$trans('sales.tags');
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-open-sales {

  .box {
    position: relative;
    padding: 30px;
    background: #F0F3F4;
    border-radius: 6px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      height: 369px;
      left: -96px;
      top: -150px;
      width: 369px;
      background: radial-gradient(50% 50% at 50% 50%, #9EC93B 0%, rgba(158, 201, 59, 0) 100%);
      opacity: .15;
    }
  }

  .title {
    position: relative;
    margin: 0 0 32px;
    z-index: 1;
  }

  picture {
    display: block;
    min-height: 200px;
  }

  picture img {
    object-fit: cover;
    margin: 0 -30px -30px;
    width: calc(100% + 60px);
    max-width: none;
  }

  .logo {
    position: relative;
    margin: 25px 0 0;
    z-index: 1;
  }

  .is-sales {
    background-color: #9EC93B;
    border-color: #9EC93B;
  }

  .tags {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 20px;
  }

  .tag {
    margin: 0 8px 8px 0;
    padding: 6px 14px;
    background: #FFFFFF;
    color: #676F74;
    line-height: 1.3;
    border-radius: 4px;
    box-shadow: 0 4px 4px rgba(36, 36, 36, 0.05);
    cursor: default;
  }
}

@include desktop {
  .is-section-open-sales {

    .box {
      display: flex;
      padding: 40px;
    }

    .logo {
      margin: 0 0 0 20px;
    }

    .footer {
      display: flex;
      align-items: center;
      margin: 30px 0 0;
    }

    picture img {
      position: absolute;
      margin: 0;
      bottom: 0;
      right: 0;
      max-width: 400px;
    }

    .tags {
      max-width: 500px;
    }

    .tag {
      font-size: 14px;
    }
  }
}

@include widescreen {
  .is-section-open-sales {

    picture img {
      max-width: 500px;
    }
  }
}
</style>