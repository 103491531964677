<template>
  <section ref="section" class="section is-section-about-project">
    <div class="container">
      <h2 :class="['title is-uppercase', {'is-expanded': expanded}]" @click="toggle">{{ title }}</h2>

      <transition name="slide">
        <div v-if="expanded" class="features">
          <div :class="['feature', 'is-' + index]" :key="index" v-for="(feature, index) in features">
            <div class="content">
              <h5 class="feature-title is-uppercase">{{ feature?.title }}</h5>
              <p :key="i" v-for="(p, i) in feature.p" v-html="p"></p>
            </div>

            <div class="media">
              <picture>
                <source media="(min-width: 640px)" :srcset="feature.images.tablet">
                <img :class="['image is-cover', {'is-last': index === features.length - 1}]" :src="feature.images.mobile" alt="">
              </picture>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>

import {ResizeSensor} from 'css-element-queries';

export default {
  props: {
    title: {
      type: String,
      required: true
    },

    features: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      expanded: false,
      isDesktop: window.matchMedia(('(min-width: 940px)')).matches
    }
  },

  mounted() {
    new ResizeSensor(this.$refs.section, () => {
      if (this.isDesktop) {
        this.expanded = true;
      }
    });
  },

  methods: {
    toggle() {
      if (!this.isDesktop) {
        this.expanded = !this.expanded;
      }
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-about-project {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.is-expanded {
      &:after {
        top: 6px;
        transform: rotate(-45deg);
      }
    }

    &:after {
      content: '';
      position: relative;
      top: -6px;
      @include chevron('bottom', 20px, 3px, $info);
    }
  }

  .feature-title {
    margin: 30px 0 20px;
  }

  .is-cover {
    display: block;
    margin: 0 -30px;
    width: calc(100% + 60px);
    max-width: none;
  }

  .is-last {
    margin-bottom: -30px;
  }

  p:last-child {
    margin: 0 0 30px;
  }
}

@include tablet {
  .is-section-about-project {

    .is-cover {
      display: block;
      margin: 0;
      width: auto;
      max-width: 100%;
    }
  }
}

@include desktop {
  .is-section-about-project {
    background-color: $gray-light;
    padding-top: 0 !important;

    .title {
      margin: 0 0 40px;

      &:after {
        display: none;
      }
    }

    .feature {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0;

      p {
        padding-right: 20px;
      }

      &.is-1 {
        flex-direction: row-reverse;

        .feature-title {
          padding-left: 20px;
        }

        p {
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }

    .feature-title {
      margin-top: 0;
    }

    .content {
      width: 50%;
      flex: none;
      font-size: 16px;
    }

    p {
      margin: 0 0 10px;
    }

    p:last-child {
      margin: 0;
    }
  }
}

@include widescreen {
  .is-section-about-project {
    .content {
      width: 50%;
      flex: none;
      font-size: 20px;
    }
  }
}
</style>