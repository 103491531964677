/**
 * Import dependencies
 */
import { createApp } from 'vue'
import App from './App.vue'
import DotObject from 'dot-object';
import mitt from 'mitt';
import smoothscroll from 'smoothscroll-polyfill';
import * as ru from './lang/ru.json'
import lozad from "lozad";

/**
 * Initialize emitter
 *
 * @type {Emitter}
 */
const emitter = mitt();

/**
 * Create app
 * @type {App<Element>}
 */
const app = createApp(App);

/**
 * Define global properties
 *
 * @param line
 */
app.config.globalProperties.$trans = line => DotObject.pick(line, ru);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$observer = lozad('.lozad', {
    rootMargin: '80px 200px'
})

/**
 * Mount app
 */
app.mount('#app');

smoothscroll.polyfill();
