<template>
  <section class="section is-section-house-award is-light">
    <div class="container">
      <div class="responsive-video">
        <iframe width="560" height="315" :src="$trans('sections.construction.award.video')" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <div class="content">
        <div class="label" v-html="$trans('sections.construction.award.label')"></div>

        <div class="logos">
          <img class="logo is-1" src="/images/logos/awards-globe-light.svg" alt="">
          <img class="logo is-2" src="/images/logos/awards-text-light.svg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import "src/assets/sass/utilites/all";

.is-section-house-award {
  color: $white;

  .container {
    margin: -30px;
  }

  .content {
    background-color: $dark;
    padding: 30px;
  }

  .label {
    margin: 0 0 30px;
    max-width: 300px;
  }

  .logos {
    display: flex;
    align-items: center;
  }

  .logo {
    margin: 0 20px 0 0;

    &.is-1 {
      width: 85px;
    }

    &.is-2 {
      width: 110px;
    }
  }
}

@include tablet {
  .is-section-house-award {
    padding-top: 0 !important;

    .container {
      margin: 0 auto;
    }

    .label {
      max-width: none;
    }
  }
}

@include desktop {
  .is-section-house-award {

    .container {
      display: flex;
    }

    .responsive-video {
      padding-bottom: 0;
      width: 51%;
      height: 343px;
      flex: none;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      flex-grow: 1;
      padding: 30px;
    }
  }
}

@include widescreen {
  .is-section-house-award {

    .responsive-video {
      height: 343px;
    }

    .content {
      padding: 50px;
    }
  }
}
</style>