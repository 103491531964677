<template>
  <footer class="app-footer">
    <div class="container">
      <div class="column is-1">
        <div class="logo-container">
          <img class="logo lozad" width="91" height="207" data-src="/images/logos/footer-logo.svg">
          <a class="logo-mask" href="https://maphome.ru"></a>
        </div>

        <nav class="nav">
          <a :key="index" v-for="(link, index) in links" class="nav-link is-uppercase" :href="link.href">
            {{ link.text }}
          </a>
        </nav>
      </div>

      <div class="column is-2">
        <h4 class="title is-spaced">{{ $trans('footer.subscribeBox.label') }}</h4>
        <div class="subscribe-box">
          <div class="icon">
            <img width="80" height="80" class="image lozad" data-src="https://maphome.ru/images/landing-open-sales/yt-logo.png">
          </div>

          <div>
            <div class="subscribe-box-title">{{ $trans('footer.subscribeBox.title') }}</div>
            <a :href="$trans('footer.subscribeBox.href')" target="_blank" class="button subscribe-box-button">
              {{ $trans('labels.subscribe') }}
            </a>
          </div>
        </div>
      </div>

      <div class="column is-3">
        <nav class="secondary-nav">
          <a class="tel" :href="'tel:' + tel"><b>{{ $trans('header.phone') }}</b></a>
          <a class="callback" @click.prevent="emitter.emit('open-form', $trans('modal.callback.title'))" href="#">
            {{ $trans('labels.callback') }}
          </a>
          <a class="whatsapp" :href="'https://wa.me/' + tel">{{ $trans('labels.writeInWhatsapp') }}</a>
          <a :href="$trans('footer.links.agreement.href')">{{ $trans('footer.links.agreement.text') }}</a>
          <a :href="$trans('footer.links.personal.href')">{{ $trans('footer.links.personal.text') }}</a>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    links() {
      return this.$trans('header.nav');
    },

    tel() {
      return this.$trans('header.phone').replace(/[^0-9]+/g, '');
    }
  },
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.app-footer {
  padding: 30px;
  background-color: $dark;
  color: $white;

  .logo-container {
    position: relative;
  }

  .logo {
    margin-right: 40px;
  }

  .logo-mask {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 115px;
  }

  .nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .nav-link {
    color: $white;
    transition: color .3s;
  }

  .subscribe-box {
    display: flex;
    align-items: flex-start;

    .icon {
      flex: none;
      margin-right: 12px;
      width: 64px;
      height: 64px;
    }

    .image {
      border-radius: 99999px;
    }

    &-title {
      margin: 0 0 12px;
      color: #ABB1B8;
      font-size: 14px;
      max-width: 160px;
    }

    &-button {
      position: relative;
      color: $white;
      background-color: #E62117;
      height: 28px;
      padding: 6px 54px 6px 6px;
      font-size: 12px;
      border-radius: 4px;
      border: none;
      overflow: hidden;

      &:after {
        content: "999+";
        position: absolute;
        background-color: $white;
        padding: 0 7px;
        height: 100%;
        right: 0;
        top: 0;
        color: #717171;
        line-height: 28px;
      }
    }
  }

  .secondary-nav {
    a {
      display: block;
      color: $white;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .tel {
      font-size: 24px;
    }

    .whatsapp {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }

  .column {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &.is-1 {
      display: flex;
    }
  }
}

@include tablet {
  .app-footer {

    .container {
      display: flex;
      flex-flow: row wrap;
    }

    .column {

      &.is-1,
      &.is-3 {
        width: 50%;
        flex: none;
      }

      &.is-1 {
        order: 1;
      }

      &.is-2 {
        order: 3;
      }

      &.is-3 {
        order: 2;
      }
    }

    .secondary-nav {
      padding: 0 0 0 30px;
    }
  }
}

@include desktop {
  .app-footer {
    .column {

      &.is-1,
      &.is-2,
      &.is-3 {
        width: auto;
        flex-grow: 1;
        order: initial;
      }
    }

    .secondary-nav {
      padding: 0;
    }

    .nav-link:hover {
      color: $info;
    }
  }
}
</style>