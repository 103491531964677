<template>
  <div>
    <form :class="theme" action="#" @submit.prevent="submit" method="POST">
      <div class="field is-grouped">
        <div class="control is-expanded">
          <input v-maska="form.phone.placeholders.focus" required class="input" @focus="focus" @blur="blur"
                 type="tel" :placeholder="form.phone.placeholders.active" v-model="form.phone.value">
        </div>

        <div class="control">
          <button type="submit" :class="['button is-primary has-arrow-right', {'is-loading': form.loading}]">
            {{ $trans('buttons.request') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {maska} from 'maska'

export default {
  directives: {
    maska
  },

  props: {
    subject: {
      type: String,
      default: 'Заявка на сайте MapHome'
    },

    theme: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      modal: {
        active: false
      },

      form: {
        loading: false,
        phone: {
          value: '',
          placeholders: this.$trans('placeholders')
        },
      }
    }
  },

  watch: {
    'modal.active': function () {
      const el = document.querySelector('html');

      this.modal.active ? el.classList.add('no-scroll') : el.classList.remove('no-scroll')
    },
  },

  methods: {
    focus() {
      this.form.phone.placeholders.active = this.form.phone.placeholders.focus;
    },

    blur() {
      this.form.phone.placeholders.active = this.form.phone.placeholders.blur;
    },

    submit() {
      this.form.loading = true;

      const data = new FormData();
      data.append('phone', this.form.phone.value);
      data.append('subject', this.subject);

      fetch('/request.php', {method: 'POST', body: data})
          .then(response => response.json())
          .then(() => {
            this.emitter.emit('open-modal-success');
            this.form.phone.value = '';
            this.form.loading = false;
          });
    }
  }
}
</script>
