<template>
  <div class="view investment-view">
    <div class="sticky-close">
      <button type="button" @click.prevent="emitter.emit('close-expanded', 'investment')" class="close">X</button>
    </div>

    <app-objects :label="$trans('sections.investment.slider.label')"
                 :notice="$trans('sections.investment.slider.notice')"
                 :objects="$trans('sections.investment.slider.objects')">
    </app-objects>

    <app-base-form :title="$trans('sections.investment.form.title')" :subtitle="$trans('sections.investment.form.subtitle')">
      <template v-slot:cover>
        <picture>
          <source media="(min-width: 1260px)" srcset="/images/sections/requests/investment/widescreen@2x.jpg">
          <source media="(min-width: 940px)" srcset="/images/sections/requests/investment/desktop@2x.jpg">
          <source media="(min-width: 640px)" srcset="/images/sections/requests/investment/tablet@2x.jpg">
          <img width="750" height="522" class="image is-cover" src="/images/sections/requests/investment/mobile@2x.jpg" alt="">
        </picture>
      </template>
    </app-base-form>
  </div>
</template>

<script>
import AppBaseForm from "@/components/AppBaseForm";
import AppObjects from "@/components/AppObjects";

export default {
  components: {AppObjects, AppBaseForm}
}
</script>