<template>
  <div class="view tuning-view">
    <div class="sticky-close">
      <button type="button" @click.prevent="emitter.emit('close-expanded', 'tuning')" class="close">X</button>
    </div>

    <app-pricing :title="$trans('sections.tuning.pricing.title')"
                 :label="$trans('sections.tuning.pricing.label')"
                 :list="$trans('sections.tuning.pricing.list')"></app-pricing>

    <app-tuning-compares :label="$trans('sections.tuning.facade.label')"
                         :notice="$trans('sections.tuning.facade.notice')"
                         :table="$trans('sections.tuning.facade.table')"
                         :slides="$trans('sections.tuning.facade.slides')"
                         :gallery="$trans('sections.tuning.facade.gallery')"
    >
      <app-base-form class="is-request" :title="$trans('sections.tuning.form.title')"></app-base-form>
    </app-tuning-compares>

    <app-blog :title="$trans('zen.title')" :subtitle="$trans('zen.subtitle')">
      <a :href="$trans('zen.link')" class="button is-outlined has-arrow-right">{{ $trans('buttons.read') }}</a>
    </app-blog>

    <app-tuning-compares :label="$trans('sections.tuning.reconstruction.label')"
                         :slides="$trans('sections.tuning.reconstruction.slides')"
                         :notice="$trans('sections.tuning.reconstruction.notice')"
                         :table="$trans('sections.tuning.reconstruction.table')"
                         :gallery="$trans('sections.tuning.reconstruction.gallery')"
    >
      <app-base-form class="is-request" :title="$trans('sections.tuning.form.title')"></app-base-form>
    </app-tuning-compares>
  </div>
</template>

<script>
import AppPricing from "@/components/AppPricing";
import AppBlog from "@/components/AppBlog";
import AppTuningCompares from "@/components/AppTuningCompares";
import AppBaseForm from "@/components/AppBaseForm";

export default {
  components: {AppBaseForm, AppTuningCompares, AppBlog, AppPricing}
}
</script>
