<template>
  <section class="section is-dark is-section-about">
    <div class="container">
      <img width="254" height="88" data-src="/images/logos/cada-logo-white.svg" class="image is-logo lozad">

      <div class="content">
        <p :key="index" v-for="(p, index) in paragraphs">{{ p }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    paragraphs() {
      return this.$trans('about.p')
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-about {
  position: relative;
  padding: 60px 30px !important;
  color: $white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: #1f1f1f linear-gradient(180deg, #000, transparent);
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .is-logo {
    max-width: 255px;
    margin: 0 0 40px;
  }
}

@include tablet {
  .is-section-about {

    .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    .is-logo {
      margin: 0 45px 0 0;
    }
  }
}

@include desktop {
  .is-section-about {
    padding: 90px 30px !important;

    .is-logo {
      margin: 0 55px 0 0;
    }

    .content {
      display: flex;
      flex-flow: row nowrap;

      p:not(:last-child) {
        margin: 0 30px 0 0;
      }
    }
  }
}

@include widescreen {
  .is-section-about {
    padding: 120px 30px !important;

    .content {
      p:not(:last-child) {
        margin: 0 86px 0 0;
      }
    }

    .is-logo {
      flex: 0 0 auto;
      margin: 0 72px 0 0;
      width: 348px;
    }
  }
}
</style>