<template>
  <section class="section is-light is-section-released-project">
    <div class="container">
      <div class="label is-uppercase has-color-gray-dark">{{ label }}</div>

      <div class="desktop-container">
        <slot></slot>

        <ul class="properties">
          <li class="property" :key="index" v-for="(item, index) in list">
            <span class="property-label">{{ item.label }}</span> <b>{{ item.value }}</b>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-released-project {
  .label {
    margin: 0 0 30px;
  }

  .logo {
    margin: 0 0 30px;
    max-width: 250px;
  }

  .property {
    &:not(:last-child) {
      margin: 0 0 6px;
    }
  }

  .property-label {
    display: inline-flex;
    width: 230px;
  }
}

@include tablet {
  .is-section-released-project {

    .logo {
      max-width: 350px;
    }

    .property {
      display: flex;
      justify-content: space-between;
    }

    .property-label {
      width: auto;
    }
  }
}

@include desktop {
  .is-section-released-project {

    .desktop-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      margin: 0;

      &.is-volna {
        max-width: 260px;
      }
    }

    .properties {
      width: 50%;
      flex: none;
    }
  }
}
</style>