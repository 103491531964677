<template>
  <div class="view development-view">
    <div class="sticky-close">
      <button type="button" @click.prevent="emitter.emit('close-expanded', 'development')" class="close">X</button>
    </div>

    <app-pricing :title="$trans('sections.development.pricing.title')"
                 :label="$trans('sections.development.pricing.label')"
                 :list="$trans('sections.development.pricing.list')"></app-pricing>

    <app-realeased-project :label="$trans('sections.development.project.label')"
                           :list="$trans('sections.development.project.list')">
      <img class="logo is-volna" width="1268" height="760" src="/images/logos/vidnaya-volna.svg" alt="">
    </app-realeased-project>

    <app-about-project :title="$trans('sections.development.about.title')"
                       :features="$trans('sections.development.about.features')">
    </app-about-project>

    <app-blog :title="$trans('zen.title')" :subtitle="$trans('zen.subtitle')">
      <a :href="$trans('zen.link')" class="button is-outlined has-arrow-right">{{ $trans('buttons.read') }}</a>
    </app-blog>

    <app-base-form :title="$trans('sections.development.form.title')">
      <template v-slot:cover>
        <picture>
          <source media="(min-width: 1260px)" srcset="/images/sections/requests/vidnaya-volna/widescreen@2x.jpg">
          <source media="(min-width: 940px)" srcset="/images/sections/requests/vidnaya-volna/desktop@2x.jpg">
          <source media="(min-width: 640px)" srcset="/images/sections/requests/vidnaya-volna/tablet@2x.jpg">
          <img width="750" height="528" class="image is-cover" src="/images/sections/requests/vidnaya-volna/mobile@2x.jpg" alt="">
        </picture>
      </template>
    </app-base-form>
  </div>
</template>

<script>
import AppPricing from "@/components/AppPricing";
import AppRealeasedProject from "@/components/AppRealeasedProject";
import AppAboutProject from "@/components/AppAboutProject";
import AppBaseForm from "@/components/AppBaseForm";
import AppBlog from "@/components/AppBlog";

export default {
  components: {AppBlog, AppBaseForm, AppAboutProject, AppRealeasedProject, AppPricing}
}
</script>
