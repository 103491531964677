<template>
  <section class="section is-section-philosophy">
    <div class="container">
      <span class="label is-uppercase">{{ $trans('philosophy.label') }}</span>

      <div class="content">
        <picture>
          <source media="(min-width: 640px)" :srcset="$trans('philosophy.images.tablet')">
          <img width="750" height="398" class="image is-cover" :src="$trans('philosophy.images.mobile')">
        </picture>

        <div>
          <h2 class="title is-uppercase">{{ $trans('philosophy.title') }}</h2>
          <p :key="index" v-for="(p, index) in paragraphs">{{ p }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    paragraphs() {
      return this.$trans('philosophy.p')
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-philosophy {
  position: relative;
  overflow: hidden;

  .is-cover {
    margin: 30px -30px 30px;
    width: calc(100% + 60px);
    max-width: none;
  }

  .label {
    display: block;
    color: $gray-dark;
  }

  .title {
    margin: -120px 0 40px;
    color: $white;
    max-width: 220px;
  }
}

@include tablet {
  .is-section-philosophy {

    .title {
      margin: 30px 0 40px;
      color: $dark;
      max-width: 400px;
    }

    .is-cover {
      margin: 50px 0 0;
      width: auto;
      max-width: 100%;
    }
  }
}

@include desktop {
  .is-section-philosophy {
    padding-bottom: 120px !important;

    .label {
      margin: 0 0 50px;
    }

    picture {
      flex: none;
      width: 50%;
    }

    .is-cover {
      position: absolute;
      margin: 0;
      right: calc(50% + 30px);
      max-height: 372px;
    }

    .content {
      display: flex;
    }

    .title {
      margin-top: 0;
    }
  }
}

@include widescreen {
  .is-section-philosophy {
    .is-cover {
      max-height: 384px;
    }
  }
}

</style>