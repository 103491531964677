<template>
  <section class="section is-light is-section-compares">
    <div class="container">

      <div class="label is-uppercase has-color-gray-dark">{{ label }}</div>
      <p class="notice">{{ notice }}</p>

      <div class="gallery-container">
        <div class="gallery">
          <img @click="emitter.emit('open-gallery', image.original)" :key="index"
               v-for="(image, index) in gallery" :src="image.thumb">
        </div>

        <swiper v-if="slides" @swiper="onSwiper" :noSwiping="true" noSwipingClass="swiper-slide" :slides-per-view="1" :loop="true">
          <swiper-slide :key="index" v-for="(slide, index) in slides">
            <vue-compare-image :left-label="$trans('labels.before')"
                               :left-image="slide[0]"
                               :right-label="$trans('labels.after')"
                               :right-image="slide[1]">
            </vue-compare-image>
          </swiper-slide>

          <button type="button" @click.prevent="swiper.slideNext()" class="swiper-arrow-left"></button>
          <button type="button" @click.prevent="swiper.slidePrev()" class="swiper-arrow-right"></button>
        </swiper>
      </div>

      <div class="table-container">
        <div class="table">
          <h5 class="thead">{{ table.thead }}</h5>

          <ul class="tbody">
            <li :class="['tr', {'is-total': i === table.tbody.length - 1}]" :key="i" v-for="(tr, i) in table.tbody">
              <span>{{ tr.label }}</span> <span>{{ tr.value }}</span>
            </li>
          </ul>
        </div>

        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import VueCompareImage from "@/components/VueCompareImage";
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  components: {VueCompareImage, Swiper, SwiperSlide},
  props: {
    label: {
      type: String,
      required: true
    },

    notice: {
      type: String,
      required: true
    },

    table: {
      type: Object,
      required: true
    },

    slides: {
      type: Array
    },

    gallery: {
      type: Array
    }
  },

  data() {
    return {
      swiper: null
    }
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-compares {

  .notice {
    margin: 10px 0 30px;
  }

  .gallery {
    display: none;

    img {
      cursor: pointer;
    }
  }

  .swiper {
    margin: 0 -30px 0;
    width: calc(100% + 60px);
  }

  .thead {
    margin: 30px 0 10px;
  }

  .tbody {
    padding: 10px 0 30px;
  }

  .tr {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &.is-total {
      font-weight: $weight-bold;
    }

    &:not(:last-child) {
      margin: 0 0 10px;
      padding: 0 0 10px;
      border-bottom: 1px solid $gray-dark;
    }

    span:first-child {
      display: inline-block;
      margin: 0 15px 0 0;
    }

    span:last-child {
      white-space: nowrap;
    }
  }

  .is-request {
    margin: 0 -30px -30px;
    background-color: $white;
  }
}

@include tablet {
  .is-section-compares {

    .swiper {
      margin: 0;
      width: auto;
    }

    .gallery {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      flex: none;

      img {
        margin: 0 0 20px;
        width: calc(50% - 10px);
      }
    }

    .is-request {
      margin-left: calc(calc((100vw - 100%) / -2) - 30px);
      margin-right: calc(calc((100vw - 100%) / -2) - 30px);
      padding-left: calc((100vw - 100%) / 2);
      padding-right: calc((100vw - 100%) / 2);
      margin-bottom: -60px;
    }

    .swiper-arrow-left,
    .swiper-arrow-right {
      display: none;
    }
  }
}

@include desktop {
  .is-section-compares {

    .gallery-container {
      display: flex;
      align-items: flex-start;
    }

    .gallery {
      margin-right: 20px;
      width: calc(50% - 13px);

      img {
        &:nth-child(3),
        &:nth-child(4) {
          margin: 0;
        }
      }
    }

    .table-container {
      display: flex;
      align-items: center;
    }

    .table {
      flex: none;
      width: 50%;
    }

    .tr:not(:last-child) {
      margin: 0;
      border: none;
    }

    .is-request {
      margin: 0;
      padding: 30px 60px;
      background-color: $gray-light;
      width: 40%;
      flex: none;

      .form {
        width: 100%;
      }
    }
  }
}
</style>