<template>
  <div class="modal is-active" v-if="active">
    <div class="background" @click="close"></div>

    <div class="content">
      <div class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
          <path fill="#ABB1B8" d="M13.412 12L36 34.588 34.588 36 12 13.412 13.412 12z"/>
          <path fill="#ABB1B8" d="M36 13.412L13.412 36 12 34.588 34.588 12 36 13.412z"/>
        </svg>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    }
  },

  methods: {
    close() {
      this.active = false;
    },

    open() {
      this.active = true;
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .8);
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .content {
    position: relative;
    max-width: 640px;
    max-height: 100%;
    border-radius: 4px;
    overflow: auto;
    z-index: 1;
  }

  .box {
    background-color: $white;
    padding: 30px;
  }
}
</style>