<template>
  <section ref="section" class="section is-section-video">
    <div class="container">
      <span class="label is-uppercase" v-html="$trans('videos.label')"></span>

      <button @click.prevent="toggleSound" type="button" class="button is-muted">
        <svg v-if="muted" class="speaker" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path fill="#fff" d="M4.34 2.93L2.93 4.34 7.29 8.7 7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06a8.94 8.94 0 003.61-1.75l2.05 2.05 1.41-1.41L4.34 2.93zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41v3.76zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zm-7-8l-1.88 1.88L12 7.76V4zm4.5 8A4.5 4.5 0 0014 7.97v1.79l2.48 2.48c.01-.08.02-.16.02-.24z"/>
        </svg>

        <svg v-else class="speaker" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path fill="#fff" d="M3 9v6h4l5 5V4L7 9H3Zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02ZM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77Z"/>
        </svg>

        <span class="is-text">{{ muted ? $trans('buttons.turnOnSound') : $trans('buttons.turnOffSound') }}</span>
      </button>
    </div>

    <swiper @swiper="onSwiper" @slideChange="onSlideChange" :slides-per-view="1" :loop="true">
      <swiper-slide v-slot="{ isActive }" :key="index" v-for="(video, index) in videos">
        <div class="responsive-video">
          <video @ended="swiper.slidePrev()"
                 v-if="isActive" class="player lozad" playsinline autoplay :muted="muted" :data-src="video"></video>
        </div>
      </swiper-slide>

      <button type="button" @click.prevent="swiper.slideNext()" class="swiper-arrow-left"></button>
      <button type="button" @click.prevent="swiper.slidePrev()" class="swiper-arrow-right"></button>
    </swiper>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

export default {
  components: {Swiper, SwiperSlide},

  data() {
    return {
      swiper: null,
      muted: true,
      lastScrollTop: 0,
      isDesktop: window.matchMedia('(min-width: 940px)').matches,
      delay: 500,
      elements: {
        html: document.querySelector('html'),
        header: document.querySelector('.app-header')
      }
    }
  },

  mounted() {
    setTimeout(this.$observer.observe, this.delay);

    if (this.isDesktop) {
      window.addEventListener('scroll', this.bindBreakpoint);
    }
  },

  computed: {
    videos() {
      return this.$trans('videos.list');
    }
  },

  methods: {
    bindBreakpoint() {
      const position = this.$refs.section.offsetTop - this.elements.header.offsetHeight;
      const scrollTop = window.pageYOffset;

      if (position < scrollTop && scrollTop > this.lastScrollTop) {
        this.elements.html.classList.add('no-scroll');

        this.unbindBreakpoint();
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    },

    unbindBreakpoint() {
      setTimeout(() => {
        this.elements.html.classList.remove('no-scroll');

        window.removeEventListener('scroll', this.bindBreakpoint);
      }, this.delay);
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      //this.muted = true;
      setTimeout(this.$observer.observe, 500);
    },

    toggleSound() {
      const video = this.$el.querySelector('.swiper-slide-active video');

      video.muted = this.muted = !video.muted;

      if (!video.muted) {
        video.currentTime = 0;
      }
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-video {
  position: relative;
  background-color: #1f1f1f;
  padding: 0 !important;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(36, 36, 36, .3), rgba(36, 36, 36, 0));
    z-index: 1;
  }

  .container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    width: 100%;
    z-index: 1;
  }

  .swiper-arrow-left,
  .swiper-arrow-right {
    display: none;
  }

  .speaker {
    margin: 0;
  }

  .is-text {
    display: none;
  }

  .label {
    position: relative;
    left: 30px;
    color: $white;

    span {
      display: none;
    }
  }

  .swiper {
    z-index: 0;
  }

  .player {
    width: 100%;
    display: block;
  }
}

@include tablet {
  .is-section-video {
    padding: 0 !important;

    &:after {
      display: none;
    }

    &:before {
      top: 0;
    }

    .container {
      left: 50%;
      transform: translateX(-50%);
    }

    .label {
      left: 0;

      span {
        display: initial;
      }
    }

    .button {
      right: 0;
    }
  }
}

@include desktop {
  .is-section-video {

    .container {
      padding: 30px 0;
    }

    .swiper-arrow-left,
    .swiper-arrow-right {
      display: initial;
    }

    .responsive-video {
      height: calc(100vh - 88px);
      padding: 0 !important;

      video {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0 auto;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
      }
    }

    .speaker {
      margin: 0 10px 0 0;
    }

    .is-text {
      display: block;
    }
  }
}

</style>