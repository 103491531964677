<template>
  <div ref="header" :class="['app-header', {'is-opened': opened}]">
    <div class="container">
      <div @click="toggle" class="navbar-mobile-background"></div>

      <div class="navbar-left">
        <picture class="logo">
          <source media="(min-width: 640px)" srcset="/images/logos/white.svg">
          <img src="/images/logos/cada-logo-white-short.svg" alt="Cada">
        </picture>

        <a target="_blank" class="quiz-link" href="https://quiz.cada.info">Выбрать стиль</a>
      </div>

      <div class="navbar-center">
        <div class="navbar-overlay">
          <nav ref="nav" class="nav">
            <a :key="index" v-for="(link, index) in links" @click.prevent="navigate" class="nav-link" :href="link.href">
              {{ link.text }}
            </a>

            <a class="nav-link is-tel" :href="`tel:${tel}`">{{ $trans('header.phone') }}</a>
          </nav>

          <a :href="`https://wa.me/${tel}`" class="call-whatsapp">
            {{ $trans('labels.writeIn') }}
          </a>
        </div>
      </div>

      <div class="navbar-right">
        <div :class="['dropdown', {'is-opened': dropdown}]">
          <div class="dropdown-header" @click="toggleLang">RUS</div>

          <div class="dropdown-body">
            <a class="dropdown-item is-cn" href="/cn">CHN</a>
            <a class="dropdown-item is-en" href="/en">ENG</a>
            <a class="dropdown-item is-ar" href="/ar">ARA</a>
          </div>
        </div>

        <a class="phone-tablet" :href="`tel:${tel}`">{{ $trans('header.phone') }}</a>

        <button @click="toggle" class="app-hamburger">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      dropdown: false
    }
  },

  computed: {
    links() {
      return this.$trans('header.nav');
    },

    tel() {
      return this.$trans('header.phone').replace(/[^0-9]+/g, '');
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.bindActiveStates().bindDesktopNav();
    });
  },

  methods: {
    bindDesktopNav() {
      const philosophy = document.querySelector('.is-section-philosophy');
      const offset = philosophy.offsetTop - this.$refs.header.offsetHeight;
      const classList = this.$refs.nav.classList;

      (window.scrollY > offset) ? classList.add('is-visible') : classList.remove('is-visible');

      return this;
    },

    bindActiveStates() {
      const sections = document.querySelectorAll('.nav-link:not(.is-tel)');

      [...sections].forEach(section => {
        const el = document.querySelector(section.getAttribute('href'));
        const offsetTop = el.offsetTop - this.$refs.header.offsetHeight;
        const height = (el.offsetTop + el.offsetHeight) - this.$refs.header.offsetHeight;
        const classList = section.classList;

        (offsetTop < scrollY && height > scrollY) ? classList.add('is-active') : classList.remove('is-active');
      });

      return this;
    },

    toggle() {
      this.opened = !this.opened;
      document.querySelector('html').classList.toggle('no-scroll');
    },

    isDesktop() {
      return window.matchMedia('(min-width: 960px)').matches;
    },

    navigate($event) {
      const hash = $event.target.getAttribute('href');
      const offsetTop = document.querySelector(hash).offsetTop;
      const headerHeight = document.querySelector('.app-header').offsetHeight - 1;
      const top = offsetTop - headerHeight;

      window.scroll({top, left: 0, behavior: 'smooth'});

      if (!this.isDesktop()) {
        this.toggle()
      }
    },

    toggleLang() {
      this.dropdown = !this.dropdown;
    }
  }
}
</script>

<style lang="scss">

@import "../assets/sass/utilites/all";

.app-header {
  position: sticky;
  padding: 0 30px;
  top: 0;
  background-color: #1f1f1f;
  z-index: 3;

  &.is-opened {
    .navbar-mobile-background {
      display: block;
      z-index: 1;
    }

    .navbar-center {
      width: 210px;
      transform: translateX(0);
    }

    .navbar-overlay {
      overflow: auto;
    }

    .call-whatsapp {
      padding: 0 0 0 20px;
      font-size: initial;
      position: relative;
      right: 0;
      bottom: 20px;

      &:after {
        margin: 0 0 0 10px;
      }
    }

    .app-hamburger {
      z-index: 9;
      position: absolute;
      top: auto;
      right: 20px;

      span {
        background-color: $dark;
      }

      span:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 12px;
      }

      span:nth-child(2) {
        visibility: hidden;
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
        position: relative;
        top: -12px;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
  }

  .logo img {
    display: block;
    width: 29px;
    height: 29px;
  }

  .quiz-link {
    display: block;
    margin-left: 10px;
    color: $info;
    font-size: 16px;
  }

  .dropdown {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 30px 0 0;
    color: $white;

    &.is-opened {

      .dropdown-header {
        &:after {
          top: 3px;
          transform: rotate(-45deg);
        }
      }

      .dropdown-body {
        display: flex;
      }
    }
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
      content: '';
      position: relative;
      top: -3px;
      margin: 0 0 0 10px;
      @include chevron('bottom', 12px, 1px, $white)
    }
  }

  .dropdown-body {
    display: none;
    position: absolute;
    flex-flow: column nowrap;
    top: calc(100% + 16px);
    right: 0;
    background-color: $dark;
  }

  .dropdown-item {
    color: $white;
    padding: 10px 20px;
  }

  .navbar-left {
    display: flex;
    align-items: center;
  }

  .navbar-center {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    transform: translateX(210px);
    transition: transform .3s;
    z-index: 1;
  }

  .navbar-overlay {
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
  }

  .navbar-mobile-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .6);
  }

  .nav {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px 30px;
  }

  .nav-link {
    display: block;
    margin: 0 0 15px;
    color: $dark;

    &.is-tel {
      margin: 15px 0 0;
      font-size: 16px;
      font-weight: $weight-bold;
    }
  }

  .phone-tablet {
    display: none;
    margin: 0 30px 0 0;
    color: $white;
    font-weight: $weight-bold;
  }

  .call-whatsapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #00BD45;
    white-space: nowrap;
    color: $white;
    border-radius: 9999px;
    position: fixed;
    right: 230px;
    bottom: 20px;
    font-size: 0;
    transition: all .3s;

    &:after {
      content: '';
      display: inline-flex;
      width: 42px;
      height: 42px;
      background: url("/src/assets/images/whatsapp.svg");
      box-shadow: 0 0 12px rgba($black, .4);
      border-radius: 99999px;
    }
  }

  .navbar-right {
    display: flex;
  }

  .app-hamburger {
    width: 32px;
    display: inline-block;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    cursor: pointer;

    span {
      display: block;
      height: 2px;
      background-color: $white;

      &:not(:last-child) {
        margin: 0 0 10px;
      }
    }
  }
}

@include tablet {
  .app-header {
    .quiz-link {
      display: none;
    }

    .container {
      height: 88px;
    }

    .logo img {
      width: 159px;
      height: 55px;
    }

    .phone-tablet {
      display: block;
    }
  }
}

@include desktop {
  .app-header {

    .navbar-center {
      position: relative;
      width: auto;
      transform: none;
    }

    .navbar-overlay {
      position: initial;
      background-color: transparent;
    }

    .nav {
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      opacity: 0;
      transition: opacity .3s;

      &.is-visible {
        opacity: 1;
      }
    }

    .nav-link {
      color: $white;
      margin: 0 10px;
      transition: color .3s;

      &.is-active,
      &:hover {
        color: $info;
      }

      &.is-tel {
        display: none;
      }
    }

    .dropdown {
      font-size: 14px;
    }

    .dropdown-item {
      &:hover {
        color: $info;
      }
    }

    .phone-tablet {
      margin: 0;
    }

    .call-whatsapp {
      display: none;
    }

    .app-hamburger {
      display: none;
    }
  }
}

@include widescreen {
  .app-header {

    .nav {
      font-size: 18px;
    }

    .dropdown {
      font-size: 18px;
    }
  }
}

</style>