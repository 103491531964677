<template>
  <section class="section is-section-pricing">
    <div class="container">
      <span class="label is-uppercase">{{ label }}</span>
      <h2 class="title is-uppercase">{{ title }}</h2>

      <ul class="list">
        <li class="list-item" :key="index" v-for="(item, index) in list">
          <span>
            {{ item.label }}
          </span>
          <b>{{ item.value }}</b>
        </li>
      </ul>

      <button @click="emitter.emit('open-form', $trans('modal.form.title'))"
              type="button" class="button is-primary has-arrow-right">
        {{ $trans('buttons.order') }}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-pricing {
  .label {
    display: block;
    margin: 0 0 20px;
    color: #BBBDBF;
  }

  .title {
    margin: 0 0 20px;
  }

  .list {
    margin: 0 0 20px;
  }

  .list-item {
    margin: 0 0 10px;

    u {
      color: #BBBDBF;
      font-weight: $weight-bold;
    }

    span {
      display: block;
    }
  }
}

@include tablet {
  .is-section-pricing {

    .title {
      margin: 0 0 40px;
    }

    .list-item {
      display: flex;
      justify-content: space-between;

      u {
        font-size: 14px;
      }
    }
  }
}

@include desktop {
  .is-section-pricing {


    .list-item {
      justify-content: flex-start;

      span {
        width: 50%;
      }
    }
  }
}

</style>