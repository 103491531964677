<template>
  <section class="section is-dark is-section-blog">
    <div class="container">
      <h2 class="title is-spaced is-uppercase" v-html="title"></h2>

      <div class="actions">
        <div class="subtitle" v-html="subtitle"></div>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-blog {
  background: url("/src/assets/images/cada-dark-opacity.svg") no-repeat right center;
  background-size: contain;

  .title {
    color: $white;
  }

  .subtitle {
    color: #BBBDBF;
    max-width: 320px;
  }

  .button {
    margin-top: 30px;
  }
}

@include tablet {
  .is-section-blog {

    .subtitle {
      max-width: 410px;
    }

    &.is-home {

      .actions {
        flex-flow: row nowrap;
      }

      .button {
        margin: -20px 0 0;
      }
    }

    .actions {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      flex-direction: column-reverse;
    }

    .button {
      margin: 0 0 20px;
    }
  }
}

@include desktop {
  .is-section-blog {
    background-size: 350px;
    background-position: right top;

    .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    .title {
      margin: 0 60px 0 0 !important;
      flex: none;
    }

    .subtitle {
      margin-bottom: -20px;
    }

    &.is-home {
      .title {
        margin: 0 40px 0 0 !important;
      }

      .actions {
        flex-direction: row-reverse;
        align-items: flex-start;
      }

      .button {
        margin: 4px 60px 0 0;
      }
    }

    .actions {
      align-items: flex-start;
    }

    .button {
      margin: 8px 0 20px;
    }
  }
}

@include widescreen {
  .is-section-blog {
    background-size: 280px;

    .container {
      align-items: center;
    }

    .title {
      margin: 0 80px 0 0 !important;
    }

    .subtitle {
      margin: 0;
    }

    &.is-home {
      .title {
        margin: 0 80px 0 0 !important;
      }

      .actions {
        flex-direction: row-reverse;
        align-items: center;
      }

      .button {
        margin: 0 80px 0 0;
      }
    }

    .actions {
      flex-flow: row nowrap;
      flex-direction: row-reverse;
      align-items: center;
    }

    .button {
      margin: 0 60px 0 0;
    }
  }
}

</style>