<template>
  <div class="view construction-view">
    <div class="sticky-close">
      <button type="button" @click.prevent="emitter.emit('close-expanded', 'construction')" class="close">X</button>
    </div>

    <app-pricing :title="$trans('sections.construction.pricing.title')"
                 :label="$trans('sections.construction.pricing.label')"
                 :list="$trans('sections.construction.pricing.list')"></app-pricing>

    <app-realeased-project :label="$trans('sections.construction.project.label')"
                           :list="$trans('sections.construction.project.list')">
      <img class="logo" width="408" height="109" src="/images/logos/nautilus-residence.svg" alt="">
    </app-realeased-project>

    <app-house-award></app-house-award>

    <app-about-project :title="$trans('sections.construction.about.title')"
                       :features="$trans('sections.construction.about.features')">
    </app-about-project>

    <app-blog :title="$trans('zen.title')" :subtitle="$trans('zen.subtitle')">
      <a :href="$trans('zen.link')" class="button is-outlined has-arrow-right">{{ $trans('buttons.read') }}</a>
    </app-blog>

    <app-base-form :title="$trans('sections.construction.form.title')">
      <template v-slot:cover>
        <picture>
          <source media="(min-width: 1260px)" srcset="/images/sections/requests/neo/widescreen@2x.jpg">
          <source media="(min-width: 940px)" srcset="/images/sections/requests/neo/desktop@2x.jpg">
          <source media="(min-width: 640px)" srcset="/images/sections/requests/neo/tablet@2x.jpg">
          <img width="750" height="528" class="image is-cover" src="/images/sections/requests/neo/mobile@2x.jpg" alt="">
        </picture>
      </template>
    </app-base-form>
  </div>
</template>

<script>
import AppPricing from "@/components/AppPricing";
import AppBlog from "@/components/AppBlog";
import AppBaseForm from "@/components/AppBaseForm";
import AppAboutProject from "@/components/AppAboutProject";
import AppRealeasedProject from "@/components/AppRealeasedProject";
import AppHouseAward from "@/components/AppHouseAward";

export default {
  components: {AppHouseAward, AppRealeasedProject, AppAboutProject, AppBaseForm, AppBlog, AppPricing}
}
</script>