<template>
  <section ref="section" class="section is-light is-section-press">
    <div class="container">
      <h2 :class="['title is-uppercase', {'is-expanded': expanded}]" @click="toggle">
        {{ $trans('press.title') }}
      </h2>

      <transition name="slide">
        <div v-show="expanded" class="bordered">
          <div class="images">
            <picture class="lozad" :key="index" v-for="(image, index) in images">
              <source media="(min-width: 640px)" :srcset="image.tablet">
              <source media="(min-width: 0px)" :srcset="image.mobile">
            </picture>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import {ResizeSensor} from 'css-element-queries';

export default {
  data() {
    return {
      expanded: false,
    }
  },

  mounted() {
    new ResizeSensor(this.$refs.section, () => {
      if (this.isDesktop()) {
        this.expanded = true;
      }
    });
  },

  computed: {
    images() {
      return this.$trans('press.images');
    }
  },

  methods: {
    isDesktop() {
      return window.matchMedia('(min-width: 1260px)').matches;
    },

    toggle() {
      if (!this.isDesktop()) {
        this.expanded = !this.expanded;
      }
    }
  }
}
</script>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-press {
  position: relative;
  overflow: hidden;

  .title {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      position: relative;
      top: -6px;
      @include chevron('bottom', 20px, 3px, $info);
    }

    &.is-expanded {
      margin: 0 0 30px;

      &:after {
        top: 6px;
        transform: rotate(-45deg);
      }
    }
  }

  .images {
    margin: 0 -30px;
    white-space: nowrap;
    overflow: auto;

    & > *:first-child {
      margin-left: 30px;
    }

    & > *:last-child {
      margin-right: 30px;
    }
  }

  picture {
    display: inline-block;
    width: calc(100vw - 40%);
    min-height: 400px;
    vertical-align: top;
    margin: 0 10px;

    img {
      display: block;
      max-height: 100%;
    }
  }
}

@include tablet {
  .is-section-press {

    .bordered {
      padding: 30px 0;
      border-top: 1px solid $dark;
      border-bottom: 1px solid $dark;
    }

    .images {
      margin-left: calc(calc((100vw - 100%) / -2) - 30px);
      margin-right: calc(calc((100vw - 100%) / -2) - 30px);
      padding-left: calc((100vw - 100%) / 2);
      padding-right: calc((100vw - 100%) / 2);
    }

    picture {
      margin: 0 10px;
      width: auto;
      height: 240px;
      min-height: auto;
    }
  }
}

@include widescreen {
  .is-section-press {
    background-color: $dark !important;

    .title {
      color: $white;
      margin: 0 0 30px;

      &:after {
        display: none !important;
      }
    }

    .bordered {
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
    }

    .images {
      margin: 0;
      padding: 0;
      overflow: hidden;

      & > *:first-child {
        margin-left: 0;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }

    picture {
      height: 188px;
      min-width: 1px;
      min-height: auto;
    }
  }
}
</style>