<template>
  <section class="section is-section-awards">
    <picture class="lozad is-cover">
      <source media="(min-width: 960px)" :srcset="$trans('awards.cover.desktop')">
      <source media="(min-width: 640px)" :srcset="$trans('awards.cover.tablet')">
      <source media="(min-width: 0px)" :srcset="$trans('awards.cover.mobile')">
    </picture>

    <div class="container">
      <h5 class="title" v-html="$trans('awards.title')"></h5>

      <div class="logos">
        <img class="logo is-1 lozad" data-src="/images/logos/awards-globe-dark.svg">
        <img class="logo is-2 lozad" data-src="/images/logos/awards-text-light.svg">
      </div>
    </div>

    <img width="78" height="78" class="image is-watermark lozad" data-src="/images/logos/cada-awards.svg">
  </section>
</template>

<style lang="scss">

@import "src/assets/sass/utilites/all";

.is-section-awards {
  position: relative;
  height: 480px;

  .container {
    position: relative;
  }

  .title {
    color: $white;
    font-weight: $weight-normal;

    br {
      display: none;
    }
  }

  .is-cover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $dark;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .logos {
    display: flex;
    margin: 20px 0 0;
  }

  .logo {
    margin-right: 15px;
  }

  .is-watermark {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 52px;
    height: 52px;
  }
}

@include tablet {
  .is-section-awards {
    height: 400px;

    .title {
      font-size: 20px;

      br {
        display: initial;
      }
    }

    .logos {
      flex-flow: column nowrap;
    }

    .logo {
      width: 75px;
      margin: 0 0 20px;
    }

    .is-watermark {
      width: 64px;
      height: 64px;
    }
  }
}

@include desktop {
  .is-section-awards {
    height: 570px;

    .title {
      font-size: 32px;
    }

    .logo {
      width: 120px;
    }

    .is-watermark {
      width: 120px;
      height: 120px;
    }
  }
}

@include widescreen {
  .is-section-awards {
    height: 680px;

    .title {
      font-size: 38px;
      max-width: 1100px;
    }

    .logo {
      width: 150px;
    }
  }
}

</style>