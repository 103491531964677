<template>
  <section class="section is-section-hero">
    <div class="container">

      <div class="content">
        <h2 class="title is-spaced is-uppercase">
          <span class="typed"></span>
          <span class="is-block">{{ $trans('hero.title') }}</span>
        </h2>

        <p class="subtitle">{{ $trans('hero.subtitle') }}</p>
      </div>

      <div class="responsive-video">
        <video class="video" autoplay playsinline muted loop :src="$trans('hero.video')"></video>
      </div>
    </div>
  </section>
</template>

<script>
import Typed from 'typed.js';

export default {

  data() {
    return {
      active: 0
    }
  },

  computed: {
    words() {
      return this.$trans('hero.words');
    }
  },

  mounted() {
    setTimeout(() => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }, 100)

    new Typed(this.$el.querySelector('.typed'), {
      strings: this.words,
      typeSpeed: 120,
      backSpeed: 70,
      loop: true
    });

    setInterval(this.rotate, 5000)
  },

  methods: {
    rotate() {
      this.active = (this.active < this.words.length - 1) ? (this.active + 1) : 0;
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/sass/utilites/all";

.is-section-hero {
  position: relative;
  padding: 0 30px !important;
  color: $white;
  background-color: $dark;
  overflow: hidden;

  .container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    min-height: calc(var(--app-height) - 140px);
  }

  .content {
    position: absolute;
    top: calc(50% - 40px);
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
  }

  .subtitle {
    color: $gray-dark;
  }

  .responsive-video {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin-left: calc(calc((100vw - 100%) / -2) - 30px);
    margin-right: calc(calc((100vw - 100%) / -2) - 30px);
    padding-left: calc((100vw - 100%) / 2);
    padding-right: calc((100vw - 100%) / 2);
    padding-bottom: calc(var(--app-height) / 2.5);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 90%;
      width: 100%;
      background: linear-gradient(180deg, $dark 0%, rgba($dark, .8) 50%, rgba($dark, 0) 100%);
      z-index: 1;
    }
  }

  .video {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%) scale(1.5);
    background-color: $dark;
  }
}

@include from(480px) {
  .is-section-hero {

    .video {
      transform: translateX(-50%) scale(1.4);
    }
  }
}

@include tablet {
  .is-section-hero {

    .container {
      min-height: calc(var(--app-height) - 220px);

      .responsive-video {
        padding-bottom: calc(var(--app-height) / 1.6);
      }
    }
  }
}

@include desktop {
  .is-section-hero {


    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: url("/src/assets/images/shadow@1020.png") no-repeat right bottom;
      background-size: contain;
      z-index: 1;
    }

    .container {
      min-height: initial;
    }

    .responsive-video {
      padding: 0 !important;
      min-height: calc(100vh - 185px);

      &:before {
        display: none;
      }

      video {
        position: absolute;
        top: auto;
        left: auto;
        right: 0;
        bottom: 0;
        width: auto;
        height: 85%;
        transform: none;
      }
    }
  }
}

@include widescreen {
  .is-section-hero {
    &:before {
      background: url("/src/assets/images/shadow@1400.png") no-repeat right bottom;
      background-size: contain;
    }
  }
}

</style>